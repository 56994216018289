import {
  Form,
  Modal,
  Input,
  Select,
  Button,
  Switch,
  Checkbox,
  message,
  DatePicker,
  InputNumber,
  CheckboxProps,
} from "antd";
import dayjs from "dayjs";
import moment from "moment";
import { useState } from "react";
import API from "../../../../config/api";
import { Col, Row } from "react-bootstrap";
import { useForm } from "antd/es/form/Form";
import TextArea from "antd/es/input/TextArea";
import { POST } from "../../../../utils/apiCalls";
import CountryCode from "../../../../components/countryCodes.json";

export const CreateModal = (props: any) => {
  const [form] = useForm();
  const [loading, setLoading] = useState(false);
  const devices = props?.office?.devices;
  const [selectedGate, setSelectedGate] = useState<any>(props?.editData?.gates);
  const [blockLoading, setBlockLoading] = useState(false);

  const indeterminate =
    selectedGate?.length > 0 && selectedGate?.length < devices?.length;
  const checkAll = devices?.length === selectedGate?.length;

  const onFinish = async (values: any) => {
    try {
      setLoading(true);
      let url = props?.editData?.id ? API.TRAINER_UPDATE : API.USER_ADD;
      let obj: any = {
        id: props?.editData?.id,
        office_name: props?.office?.name,
        office_id: props?.office?.id,
        type: "trainer",
        first_name: values?.f_name,
        last_name: values?.l_name,
        mobile_number: values?.prefix1
          ? values?.prefix1 + values?.mobile1
          : "+91" + values?.mobile1,
        mobile_number2: !values?.mobile1
          ? null
          : values?.prefix2 + values?.mobile2,
        birth_date: values?.dob
          ? moment(values?.dob).format("YYYY-MM-DDTHH:mm:ss.SSSZ")
          : null,
        place: values?.address,
        weight: Number(values?.weight),
        height: Number(values?.height),
        blood: values?.blood,
        gender: values?.gender,
        prefix: props?.office?.prefix,
        suffix: values?.suffix ?? null,
        register_date: moment(new Date()).format("YYYY-MM-DDTHH:mm:ss.SSSZ"),
        email: values?.email,
        gates: selectedGate ?? [],
        password: values?.password2,
      };
      if (!props?.editData?.id) {
        obj["status"] = true;
      }
      let response: any = await POST(url, obj);
      if (response?.status) {
        message.success("Success");
        props?.onSuccess();
      } else {
        message.error("oops.something gone wrong.");
      }
      setLoading(false);
    } catch (error) {
      message.error("oops.something gone wrong.");
      setLoading(false);
    }
  };

  const onCheckAllChange: CheckboxProps["onChange"] = (e: any) => {
    const val = e.target.checked
      ? devices.map((itm: any) => itm?.device_id)
      : [];
    form.setFieldValue("gates", val);
    handleDeviceSelection(val);
  };

  const handleDeviceSelection = (selectedValues: string[]) => {
    const selected = devices?.filter((device: any) =>
      selectedValues.includes(device?.device_id)
    );
    setSelectedGate(selected);
  };

  const blockUnblock = async (status: boolean) => {
    try {
      setBlockLoading(true);
      let api = API.BLOCK_TRAINER;
      let body = {
        id: props?.editData?.id,
        type: status,
      };
      const response: any = await POST(api, body);
      if (response?.status) {
        message.success(
          `${response?.data?.status ? "Unblocked" : "Blocked"} Successfully`
        );
        props?.onBlockUnblock();
      } else message.error("oops.something gone wrong... Status not updated");
    } catch (err) {
      message.error("oops.something gone wrong.");
    } finally {
      setTimeout(() => {
        setBlockLoading(false);
      }, 100);
    }
  };

  return (
    <Modal
      width={800}
      open={props?.open}
      onCancel={props?.onCancel}
      footer={false}
      title={`${props?.editData?.id ? "Update" : "Create"} Trainer`}
    >
      <Form
        form={form}
        onFinish={onFinish}
        initialValues={{
          f_name: props?.editData?.first_name,
          l_name: props?.editData?.last_name,
          suffix: props?.editData?.suffix,
          email: props?.editData?.email,
          gender: props?.editData?.gender,
          mobile1: props?.editData?.mobile_number?.slice(-10),
          prefix1: props?.editData?.mobile_number?.slice(0, 3) ?? "+91",
          mobile2: props?.editData?.mobile_number2?.slice(-10),
          prefix2: props?.editData?.mobile_number2?.slice(0, 3),
          dob: dayjs(props?.editData?.birth_date),
          blood: props?.editData?.blood,
          address: props?.editData?.place,
          weight: props?.editData?.weight,
          height: props?.editData?.height,
          status: props?.editData?.status,
          gates: props?.editData?.gates?.map((itm: any) => itm?.device_id),
        }}
      >
        <Row>
          <Col sm={6} xs={12}>
            <Row>
              <Col sm={6} xs={12}>
                <div className="form-label">First Name</div>
                <Form.Item
                  rules={[{ required: true, message: "Enter First Name" }]}
                  name={"f_name"}
                  style={{ marginTop: -8 }}
                >
                  <Input placeholder="First Name" />
                </Form.Item>
              </Col>
              <Col sm={6} xs={12}>
                <div className="form-label">Last Name</div>
                <Form.Item
                  rules={[{ required: true, message: "Enter Last Name" }]}
                  name={"l_name"}
                  style={{ marginTop: -8 }}
                >
                  <Input placeholder="Last Name" />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col sm={6} xs={12}>
                <div className="form-label">Gender</div>
                <Form.Item
                  rules={[{ required: true, message: "Choose a Gender" }]}
                  name={"gender"}
                  style={{ marginTop: -8 }}
                >
                  <Select
                    placeholder={"Choose a Gender"}
                    onChange={(val) => console.log(val)}
                  >
                    <Select.Option value={"male"}>Male</Select.Option>
                    <Select.Option value={"female"}>Female</Select.Option>
                  </Select>
                </Form.Item>
              </Col>

              <Col sm={6} xs={12}>
                <div className="form-label">Blood Group</div>
                <Form.Item
                  rules={[{ required: true, message: "Choose Blood Group" }]}
                  name={"blood"}
                  style={{ marginTop: -8 }}
                >
                  <Select placeholder={"Choose Blood Group"}>
                    {["A+", "A-", "B+", "B-", "AB+", "AB-", "O+", "O-"]?.map(
                      (blood: string, idx: number) => (
                        <Select.Option value={blood} key={idx}>
                          {blood}
                        </Select.Option>
                      )
                    )}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <div className="form-label">Date of Birth</div>
            <Form.Item name={"dob"} style={{ marginTop: -8 }}>
              <DatePicker
                format={"DD-MM-YYYY"}
                style={{ width: "100%" }}
                placeholder="Enter Date of Birth"
              />
            </Form.Item>
            <div className="form-label">Primary Number</div>
            <Form.Item name={"mobile1"} style={{ marginTop: -8 }}>
              <Input
                addonBefore={
                  <Form.Item name={"prefix1"} style={{ marginBottom: -1 }}>
                    <Select
                      defaultValue={"+91"}
                      size="small"
                      bordered={false}
                      style={{ width: 75 }}
                      showSearch
                    >
                      {CountryCode?.map((item: any, idx: number) => (
                        <Select.Option value={item?.dial_code} key={idx}>
                          {item?.dial_code}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                }
                placeholder="Primary Mobile Number"
              />
            </Form.Item>
            <div className="form-label">Secondary Number</div>
            <Form.Item name={"mobile2"} style={{ marginTop: -8 }}>
              <Input
                addonBefore={
                  <Form.Item name={"prefix2"} style={{ marginBottom: -1 }}>
                    <Select
                      size="small"
                      bordered={false}
                      style={{ width: 75 }}
                      showSearch
                    >
                      {CountryCode?.map((item: any, idx: number) => (
                        <Select.Option value={item?.dial_code} key={idx}>
                          {item?.dial_code}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                }
                placeholder="Secondary Mobile Number"
              />
            </Form.Item>
            <Row>
              <Col sm={6} xs={12}>
                <div className="form-label">Weigth</div>
                <Form.Item name={"weight"} style={{ marginTop: -8 }}>
                  <InputNumber
                    min={0}
                    style={{ width: "100%" }}
                    placeholder="Weight in KG"
                  />
                </Form.Item>
              </Col>
              <Col sm={6} xs={12}>
                <div className="form-label">Height</div>
                <Form.Item name={"height"} style={{ marginTop: -8 }}>
                  <InputNumber
                    min={0}
                    style={{ width: "100%" }}
                    placeholder="Height in KG"
                  />
                </Form.Item>
              </Col>
            </Row>
          </Col>
          <Col sm={6} xs={12}>
            <div className="form-label">Address</div>
            <Form.Item name={"address"} style={{ marginTop: -8 }}>
              <TextArea rows={4} placeholder="Enter Address" />
            </Form.Item>
            <div className="form-label">Email</div>
            <Form.Item name={"email"} style={{ marginTop: -8 }}>
              <Input placeholder="Email Address" />
            </Form.Item>
            <Row>
              <Col sm={6} xs={12}>
                <div className="form-label">Prefix</div>
                <Form.Item
                  initialValue={props?.office?.prefix}
                  name={"prefix"}
                  style={{ marginTop: -8 }}
                >
                  <Input disabled placeholder="Prefix" />
                </Form.Item>
              </Col>
              <Col sm={6} xs={12}>
                <div className="form-label">Admission Number</div>
                <Form.Item name={"suffix"} style={{ marginTop: -8 }}>
                  <InputNumber
                    disabled={props?.editData?.id ? true : false}
                    style={{ width: "100%" }}
                    placeholder={`${props?.office?.prefix} + ...`}
                  />
                </Form.Item>
              </Col>
            </Row>
            <div className="form-label">Create Password</div>
            <Form.Item
              rules={[
                {
                  required: props?.editData?.id ? false : true,
                  message: "Enter Password",
                },
              ]}
              name={"password1"}
              style={{ marginTop: -8 }}
            >
              <Input.Password
                disabled={props?.editData?.id ? true : false}
                placeholder="Password"
              />
            </Form.Item>
            <div className="form-label">Confirm Password</div>
            <Form.Item
              rules={[
                {
                  required: props?.editData?.id ? false : true,
                  message: "Enter Password",
                },
              ]}
              name={"password2"}
              style={{ marginTop: -8 }}
            >
              <Input.Password
                disabled={props?.editData?.id ? true : false}
                placeholder="Password"
              />
            </Form.Item>
            <div className="form-label">Gates</div>
            <Checkbox
              indeterminate={indeterminate}
              onChange={onCheckAllChange}
              checked={checkAll}
            >
              Select all
            </Checkbox>
            <div
              style={{
                width: "100%",
                borderBottom: "1px solid #e9e9e9",
                margin: "8px 0px",
              }}
            />
            <Form.Item name={"gates"} style={{ marginTop: -8 }}>
              <Checkbox.Group
                style={{ width: "100%" }}
                onChange={(selected: any) => handleDeviceSelection(selected)}
                defaultValue={props?.editData?.gates?.map(
                  (itm: any) => itm?.device_id
                )}
              >
                {devices?.map((gate: any, idx: number) => (
                  <Row style={{ margin: "4px 0px" }}>
                    <Col span={8}>
                      <Checkbox value={gate?.device_id}>
                        {gate?.device_name}
                      </Checkbox>
                    </Col>
                  </Row>
                ))}
              </Checkbox.Group>
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col sm={6}>
            {props?.editData?.id ? (
              <>
                <div className="form-label">Application Status</div>
                <Form.Item
                  name={"status"}
                  rules={[{ required: true, message: "Enter " }]}
                  valuePropName={props?.editData?.status ? "checked" : "null"}
                >
                  <Switch
                    loading={blockLoading}
                    onChange={(status: boolean) => blockUnblock(status)}
                  />
                </Form.Item>
              </>
            ) : null}
          </Col>
          <Col sm={6}>
            <br />
            <Row>
              <Col sm={6}>
                <Button block size="large" onClick={() => props?.onCancel()}>
                  Close
                </Button>
              </Col>
              <Col sm={6}>
                <Button
                  htmlType="submit"
                  block
                  size="large"
                  type="primary"
                  loading={loading}
                  disabled={blockLoading}
                >
                  Done
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

export default CreateModal;
