import "./styles.scss";
import { Container, Row, Col } from "react-bootstrap";
import { Button, Form, Input, notification } from "antd";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import TextArea from "antd/es/input/TextArea";
import API from "../../config/api";
import { ImLocation } from "react-icons/im";
import { SiMinutemailer } from "react-icons/si";
import { FaPhone } from "react-icons/fa6";
import Header from "../../components/header";
import { POST } from "../../utils/apiCalls";
function ContactScreen() {
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [notificationApi, contextHolder] = notification.useNotification();
  const [isLoading, setIsLoading] = useState(false);
  const submitHandler = async (val: any) => {
    try {
      setIsLoading(true);
      const response: any = await POST(API.CONTACT_CREATE, val);
      if (response?.status) {
        form.resetFields();
        notificationApi.success({
          duration: 8,
          placement: "top",
          message:
            "Thank you for reaching out! Your inquiry has been received, and our team will get back to you within the next 24 hours. We appreciate your interest and look forward to assisting you.",
        });
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };
  return (
    <div className="contactScreen">
      {contextHolder}
      <Header />
      <div className="contactScreen-content">
        <Container fluid={false}>
          <Row>
            <Col md={4} xs={12}>
              <div className="contactScreen-box1">
                <div className="contactScreen-txt1">CONTACT US</div>
                <Form form={form} onFinish={submitHandler}>
                  <Form.Item
                    name="name"
                    rules={[{ required: true, message: "required" }]}
                  >
                    <Input placeholder="Enter Your Name" size="large" />
                  </Form.Item>
                  <Form.Item
                    name="email"
                    rules={[{ required: true, message: "required" }]}
                  >
                    <Input placeholder="Enter Your Email" size="large" />
                  </Form.Item>
                  <Form.Item
                    name="phone"
                    rules={[{ required: true, message: "required" }]}
                  >
                    <Input placeholder="Enter Your Phone Number" size="large" />
                  </Form.Item>
                  <Form.Item
                    name="message"
                    rules={[{ required: true, message: "required" }]}
                  >
                    <TextArea
                      placeholder="Enter Your message"
                      size="large"
                      rows={4}
                    />
                  </Form.Item>
                  <Row>
                    <Col sm={6} xs={12}></Col>
                    <Col sm={6} xs={12}>
                      <Form.Item>
                        <Button
                          block
                          size="large"
                          className="contactScreen-form-submit"
                          loading={isLoading}
                          htmlType="submit"
                          type="primary"
                        >
                          Submit
                        </Button>
                      </Form.Item>
                    </Col>
                  </Row>
                </Form>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
}

export default ContactScreen;
