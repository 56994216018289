import React, { useEffect, useState } from "react";
import PageHeader from "../../components/pageHeader";
import LoadingBox from "../../components/loading";
import DataTable from "./dataTable";
import API from "../../config/api";
import { DatePicker, Input, Modal, message } from "antd";
import { GET, POST } from "../../utils/apiCalls";
import UserDetailsTable from "./components/userDetailsTable";

const Logs = () => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [selectedItem, setSelectedItem] = useState<any>({});
  const [openForm, setOpenForm] = useState(false);
  const [page, setPage] = useState<number>(1);
  const [meta, setMeta] = useState<any>();
  const pageSize = 10;
  const [date, setDate] = useState("");
  const [searchText, setSearchText] = useState("");
  const [usersModal, setUsersModal] = useState(false);
  const [listedUsers, setListedUsers] = useState([]);

  // for modal
  const [modalLoading, setModalLoading] = useState(false);
  const [modalData, setModalData] = useState([]);
  const [modalPage, setModalPage] = useState<number>(1);
  const [modalMeta, setModalMeta] = useState<any>();
  const modalPageSize = 10;

  useEffect(() => {
    getData(searchText, date);
  }, [page, date, searchText]);

  useEffect(() => {
    getModalData();
  }, [usersModal, modalPage]);

  const getData = async (qry: any, dates: any) => {
    try {
      let api = `${API.LOGGED_USERS}?order=DESC&page=${page}&take=${10}`;
      let obj = {
        date: dates,
        query: qry,
      };
      let response: any = await POST(api, obj);
      if (response?.status) {
        setData(response?.data);
        setMeta(response?.meta);
      } else {
        message.error("oops.something gone wrong.");
      }
      setLoading(false);
    } catch (err) {
      setLoading(false);
    }
  };

  const getModalData = async () => {
    try {
      setModalLoading(true);
      let api =
        API.LOGGED_USER_DETAILS + `?order=DESC&page=${modalPage}&take=${10}`;
      let response: any = await POST(api, listedUsers);
      if (response?.status) {
        setModalData(response?.data);
        setModalMeta(response?.meta);
      } else {
        message.error("oops.something gone wrong.");
      }
      setModalLoading(false);
    } catch (err) {
      setModalLoading(false);
    }
  };

  const handlePageChange = (page: number) => {
    setPage(page);
  };

  const handleModalPageChange = (modalPage: number) => {
    setModalPage(modalPage);
  };

  return (
    <div className="Offices">
      <PageHeader title={"Logs"} backButton={true}>
        <div style={{ display: "flex" }}>
          <DatePicker
            style={{ width: "50%" }}
            onChange={(val: any, date: any) => {
              setDate(date);
            }}
          />
          &nbsp;&nbsp;
          <Input.Search
            placeholder="search name, date or action..."
            allowClear
            enterButton="Search"
            onSearch={(val: any) => {
              setSearchText(val ?? "");
            }}
          />
        </div>
        <Modal
          title={"Blocked Users"}
          width={1000}
          centered
          open={usersModal}
          onCancel={() => {
            setListedUsers([]);
            setUsersModal(false);
          }}
          footer={false}
        >
          <br />
          {modalLoading ? (
            <LoadingBox />
          ) : (
            <UserDetailsTable
              data={modalData}
              handleModalPageChange={handleModalPageChange}
              meta={modalMeta}
              page={modalPage}
              pageSize={modalPageSize}
            />
          )}
        </Modal>
      </PageHeader>
      {loading ? (
        <LoadingBox />
      ) : (
        <DataTable
          data={data}
          handlePageChange={handlePageChange}
          meta={meta}
          page={page}
          pageSize={pageSize}
          setModal={(toggle: any) => setUsersModal(toggle)}
          listedUsers={(users: any) => setListedUsers(users)}
        />
      )}
    </div>
  );
};

export default Logs;
