import moment from "moment";
import { CiEdit } from "react-icons/ci";
import { RiDeleteBin5Line } from "react-icons/ri";
import { Pagination, Popconfirm, Table, Tag, Tooltip } from "antd";

const DataTable = (props: any) => {
  const columns = [
    {
      title: "S No",
      dataIndex: "",
      key: "",
      render: (text: any, record: any, index: any) => index + 1,
    },
    {
      title: "Status",
      render: (data: any) => {
        return (
          <Tag color={data?.status ? "green" : "red"}>
            {data?.status ? "Active" : "Blocked"}
          </Tag>
        );
      },
    },
    {
      title: "Admission No",
      dataIndex: "admisson_id",
      key: "admisson_id",
    },
    {
      title: "Name",
      render: (data: any) => {
        return (
          <div>
            {data?.first_name} {data?.last_name}
          </div>
        );
      },
    },
    {
      title: "Gender",
      dataIndex: "gender",
      key: "gender",
    },
    {
      title: "Blood group",
      dataIndex: "blood",
      key: "blood",
    },
    {
      title: "Office",
      dataIndex: "office_name",
      key: "office_name",
    },
    {
      title: "Created At",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (date: any) => moment(date).format("DD-MM-YYYY"),
    },
    {
      title: "Action",
      width: 5,
      render: (id: any, record: any) => (
        <div className="table-item">
          <Tooltip title="Edit" mouseEnterDelay={0.5} color="#8f8e8d">
            <CiEdit
              size={20}
              onClick={() => props?.onChange(record)}
              style={{ cursor: "pointer", color: "orange" }}
            />
          </Tooltip>
          &nbsp;&nbsp;
          <Popconfirm
            title="Delete this User"
            description="Are you sure to delete this User?"
            onConfirm={() => props?.DeleteUser(record)}
            okText="Yes"
            cancelText="No"
          >
            <RiDeleteBin5Line size={20} color="red" />
          </Popconfirm>
        </div>
      ),
    },
  ];
  return (
    <div>
      <Table
        size="small"
        dataSource={props?.data}
        pagination={false}
        columns={columns}
      />
      <Pagination
        current={props?.page || 1}
        pageSize={props?.pageSize || 10}
        total={props?.total || 0}
        defaultCurrent={1}
        responsive={true}
        defaultPageSize={props?.pageSize || 10}
        disabled={false}
        hideOnSinglePage={true}
        onChange={props?.onChange}
        style={{ marginTop: "20px", textAlign: "center" }}
      />
    </div>
  );
};

export default DataTable;
