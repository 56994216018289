import { Button, Col, Row } from "react-bootstrap";
import SpecialPlatform from "../../../assets/images/SpecialPlatform.png";

function Section5() {
  return (
    <div>
      <br /> <br /> <br /> <br />
      <Row>
        <Col md={6} className="HomeScreen-section5-box1">
          <div className="HomeScreen-section5-text1">
            Change to the specialized platform
          </div>
          <div className="HomeScreen-section5-text2">
            Xentrybook stands out as an unparalleled time management application
            designed to enhance your revenue and provide seamless access to
            business metrics, ensuring a hassle-free experience for serving your
            clients. Make the shift to Xentrybook and foster enduring
            relationships with your clients.
          </div>
          <div>
            <ul className="HomeScreen-section5-list">
              <li>
                <span>
                  Remove queues and paperwork from the reception area.{" "}
                </span>
              </li>
              <li>
                <span>
                  Ensure no overdue memberships with timely renewal reminders.
                </span>
              </li>
              <li>
                <span>
                  {" "}
                  Streamline the process for coaches to concentrate more on
                  clients.
                </span>
              </li>
              <li>
                <span>
                  Effectively, conveniently, and swiftly manage clients.
                </span>
              </li>
              <li>
                <span>Restrict any unwanted access & 100% data security</span>
              </li>
            </ul>
          </div>
          <Button className="HomeScreen-section5-btn">Contact Us</Button>
        </Col>
        <Col md={6}>
          <img
            src={SpecialPlatform}
            alt=""
            className="HomeScreen-Section5-img"
          />
        </Col>
      </Row>
      <br /> <br />
      <br /> <br />
    </div>
  );
}
export default Section5;
