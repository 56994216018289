import { useState } from "react";
import PageHeader from "../../components/pageHeader";
import { Button, Tag, message } from "antd";
import { useNavigate, useParams } from "react-router-dom";
import { Container } from "react-bootstrap";
import LoadingBox from "../../components/loading";
import { Tabs } from "antd";
import { Workbook } from "exceljs";
import { GET } from "../../utils/apiCalls";
import API from "../../config/api";
import moment from "moment";
import "./styles.scss";

import Info from "./InfoTab";
import Users from "./UsersTab";
import Trainers from "./TrainersTab";
import Transaction from "./TransactionTab";
import Subscription from "./SubscriptionTab";

function Details() {
  const { id, tab } = useParams();
  const workbook = new Workbook();
  const sheet = workbook.addWorksheet("Users Details");
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [gymStatus, setgymStatus] = useState("");
  const [activeTab, setActiveTab] = useState(tab);

  const ExportToExcel = async () => {
    try {
      let url = API.ALL_USERS;
      const response: any = await GET(url, null);
      if (response?.status) {
        await Export(response?.data);
      } else {
        message.error("Something went wrong. Couldn't Export.");
      }
    } catch (err) {
      console.log(err);
      message.error("Something went wrong. Couldn't Export.");
    }
  };

  const onChange = (tab: any) => {
    navigate(`/admin/details/${tab}/${id}`);
    setActiveTab(tab);
  };

  const items = [
    {
      key: "info",
      label: "Info",
      tab: "info",
      children: <Info gymStatus={(val: string) => setgymStatus(val)} />,
    },
    {
      key: "users",
      label: "Users",
      tab: "users",
      children: <Users />,
    },
    {
      key: "subscription",
      label: "Subscription",
      tab: "subscription",
      children: <Subscription />,
    },
    {
      key: "transaction",
      label: "Transaction",
      tab: "transaction",
      children: <Transaction />,
    },
    {
      key: "trainers",
      label: "Trainers",
      tab: "trainers",
      children: (
        <Trainers/>
      ),
    },
  ];

  sheet.columns = [
    {
      header: "Name",
      key: "name",
    },
    {
      header: "Admission ID",
      key: "admisson_id",
    },
    {
      header: "Status",
      key: "status",
    },
    {
      header: "End Date",
      key: "end_date",
    },
  ];

  const Export = async (data: any) => {
    data?.map((user: any) => {
      sheet.addRow({
        name: `${user?.first_name} ${user?.last_name}`,
        admisson_id: user?.admisson_id,
        status: user?.status,
        end_date: moment(new Date(user?.end_date)).format("DD-MM-YYYY"),
      });
    });
    await workbook.xlsx.writeBuffer().then(function (data) {
      const blob = new Blob([data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      const url = window.URL.createObjectURL(blob);
      const anchor = document.createElement("a");
      anchor.href = url;
      anchor.download = `Users_${moment(new Date()).format(
        "DD-MM-YYYY_HH:mm:ss"
      )}.xlsx`;
      anchor.click();
      window.URL.revokeObjectURL(url);
    });
  };

  return (
    <div className="Details">
      <PageHeader
        goBack={"/admin/home"}
        backButton
        title={`Details`}
        children={
          <div className="PageHeader-children">
            {tab === "info" && (
              <div className="PageHeader-gym-name">
                {gymStatus === "active" ? (
                  <Tag color="green">Active</Tag>
                ) : gymStatus === "inactive" ? (
                  <Tag color="red">Blocked</Tag>
                ) : (
                  ""
                )}
              </div>
            )}
            {tab === "users" && (
              <>
                <div style={{ width: 20 }} />
                <Button onClick={() => ExportToExcel()} type="primary">
                  Exoprt Excel
                </Button>
              </>
            )}
          </div>
        }
      />
      {loading ? (
        <LoadingBox />
      ) : (
        <Container>
          <Tabs defaultActiveKey={activeTab} onChange={onChange}>
            {items?.map((item: any) => (
              <Tabs.TabPane key={item?.key} tab={item?.label}>
                {item?.children}
              </Tabs.TabPane>
            ))}
          </Tabs>
        </Container>
      )}
    </div>
  );
}

export default Details;
