import moment from "moment";
function InvoiceTemplate({ user, office, transaction, subscription }: any) {
  function calculateNewDate(inputDate: any, duration: any, durationType: any) {
    return moment(inputDate)
      ?.add(duration, durationType)
      ?.format("ddd MMM DD YYYY");
  }
  return `<!DOCTYPE html
  PUBLIC "-//W3C//DTD XHTML 1.0 Transitional//EN" "http://www.w3.org/TR/xhtml1/DTD/xhtml1-transitional.dtd">
<html xmlns="http://www.w3.org/1999/xhtml" xmlns:v="urn:schemas-microsoft-com:vml"
  xmlns:o="urn:schemas-microsoft-com:office:office">

<head>
  <meta http-equiv="Content-type" content="text/html; charset=utf-8" />
  <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1" />
  <meta http-equiv="X-UA-Compatible" content="IE=edge" />
  <meta name="format-detection" content="date=no" />
  <meta name="format-detection" content="address=no" />
  <meta name="format-detection" content="telephone=no" />
  <meta name="x-apple-disable-message-reformatting" />
  <link href="https://fonts.googleapis.com/css?family=Muli:400,400i,700,700i" rel="stylesheet" />
  <title>Tax GO Invoice</title>
</head>
  <body >
  <div style="border: 1px dashed grey;padding:10px;">
    <table>
      <tr>
        <td>
          <img
            src="${office?.logo}"
            style="
              width: 100px;
              height: 100px;
              border-radius: 100px;
              object-fit: cover;
              background-color: gray;
            "
          />
        </td>
        <td>
          <div style="margin-left: 20px; font-size: 20px">
            <div style="font-weight: 900; margin-bottom: 5px">
              ${office?.name ?? ""}
            </div>
            <div style="font-size: 14px">${office?.phone ?? ""}</div>
          </div>
        </td>
      </tr>
    </table>
    <table style="width: 100%">
      <tr>
        <td>
          <br />
          <div style="font-weight: 900; margin-bottom: 5px">Bill To</div>
          <div>${user?.first_name + " " + user?.last_name ?? ""}</div>
          <div>${user?.mobile_number}</div>
        </td>
        <td style="display: flex; align-items: flex-end; justify-content: end">
          <div>Date: ${moment(new Date()).format("ddd MMM DD YYYY") ?? ""}</div>
        </td>
      </tr>
    </table>
    <br />
    <table
      style="
        width: 100%;
        border: 1px solid rgb(208, 208, 208);
        border-collapse: collapse;"
    >
      <tr>
        <td style="background-color: rgb(207, 207, 207); padding: 10px">
          Item
        </td>
        <td
          style="
            text-align: right;
            background-color: rgb(207, 207, 207);
            padding: 10px;
          "
        >
          Price
        </td>
      </tr>
      <tr>
        <td style="padding: 10px">
          <div>Membership - ${subscription?.title ?? ""}</div>
            <div>Renewed for ${moment(transaction?.createdAt).format(
              "ddd MMM DD YYYY"
            )} - ${
    calculateNewDate(
      transaction?.createdAt,
      subscription?.duration,
      subscription?.duration_type
    ) ?? ""
  }</div>
        </td>
        <td style="text-align: right; padding: 10px">${
          subscription?.price ?? ""
        }</td>
      </tr>
    </table>
    <br />
    <table style="width: 100%; border-collapse: collapse">
      <tr>
        <td rowspan="3">
          <div>Payment Information</div>
          <div>${transaction?.payment_method ?? ""}</div>
        </td>
        <td style="border-bottom: 1px solid rgb(208, 208, 208); padding: 10px">
          <div>SubTotal</div>
        </td>
        <td
          style="
            border-bottom: 1px solid rgb(208, 208, 208);
            padding: 10px;
            text-align: right;
          "
        >
          ${transaction?.amount ?? ""}
        </td>
      </tr>
      <tr>
        <td style="border-bottom: 1px solid rgb(208, 208, 208); padding: 10px">
          <div>Total due</div>
        </td>
        <td
          style="
            border-bottom: 1px solid rgb(208, 208, 208);
            padding: 10px;
            text-align: right;
          "
        >
          ${Number(subscription?.price) - Number(transaction?.paid_mount) ?? ""}
        </td>
      </tr>
      <tr>
        <td style="padding: 10px">
          <div>Amount Recieved</div>
        </td>
        <td style="padding: 10px; text-align: right">${
          transaction?.paid_mount ?? ""
        }</td>
      </tr>
    </table>
    <br/> <br/>
    <br/>
    <br/>
    <br/>
    <br/>
    <div style="text-align:center;">
    <img src="https://xentrybook.com/static/media/logo-dark.ef7370fe2d068fac0c9f.png" style="height:50px"/>
    <div style="font-size:14px;">This invoice is computer generated no signature required</div>
    </div>
    <br/>
    </div>
  </body>
    </html>
  `;
}
export { InvoiceTemplate };
