import moment from "moment";
import { Pagination, Table } from "antd";
import { useNavigate } from "react-router-dom";
import { CiRead } from "react-icons/ci";

const DataTable = (props: any) => {
  const navigate = useNavigate();
  const columns = [
    {
      title: "S No",
      dataIndex: "",
      key: "",
      render: (text: any, record: any, index: any) => index + 1,
    },
    {
      title: "Name",
      dataIndex: "user_name",
      key: "user_name",
    },
    {
      title: "Comment",
      dataIndex: "comment",
      key: "comment",
    },
    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
    },
    {
      title: "Paid Amount",
      dataIndex: "paid_mount",
      key: "paid_mount",
    },
    {
      title: "Payment Method",
      dataIndex: "payment_method",
      key: "payment_method",
    },
    {
      title: "Transaction ID",
      dataIndex: "transaction_id",
      key: "transaction_id",
    },
    {
      title: "Created At",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (date: any) => moment(date).format("DD-MM-YYYY"),
    },
    {
      title: "Updated At",
      dataIndex: "updatedAt",
      key: "updatedAt",
      render: (date: any) => moment(date).format("DD-MM-YYYY"),
    },
    {
      title: "Action",
      with: 10,
      dataIndex: "transaction_id",
      key: "transaction_id",
      render: (item: any) => (
        <div className="table-item">
          <div onClick={() => navigate(`/invoice/${item}`)}>
            <CiRead size={25} />
          </div>
        </div>
      ),
    },
  ];
  return (
    <>
      <br />
      <Table
        size="small"
        columns={columns}
        pagination={false}
        dataSource={props?.data}
      />
      <Pagination
        disabled={false}
        responsive={true}
        defaultCurrent={1}
        total={props?.total}
        onChange={props?.onChange}
        current={props?.page || 1}
        pageSize={props?.pageSize || 10}
        defaultPageSize={props?.pageSize || 10}
        style={{ marginTop: "20px", textAlign: "center" }}
        showTotal={(total) => `Total ${total} Transactions`}
      />
      <br />
    </>
  );
};

export default DataTable;
