import { useEffect, useState } from "react";
import PageHeader from "../../components/pageHeader";
import { Button, Input, message } from "antd";
import LoadingBox from "../../components/loading";

import API from "../../config/api";
import { GET } from "../../utils/apiCalls";
import DataTable from "./dataTable";

function Enquiries() {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [selectedItem, setSelectedItem] = useState<any>({});
  const [openForm, setOpenForm] = useState(false);
  const [page, setPage] = useState<number>(1);
  const [meta, setMeta] = useState<any>();
  const pageSize = 10;

  useEffect(() => {
    getData();
  }, [page]);

  const getData = async () => {
    let response: any = await GET(
      API.CONTACT_ALL + `?page=${page}&take=${pageSize}`,
      null
    );
    if (response?.status) {
      setData(response?.data);
      setMeta(response?.meta);
    } else {
      message.error("oops.something gone wrong.");
    }
    setLoading(false);
    try {
    } catch (err) {
      setLoading(false);
    }
  };

  const editItem = (value: any) => {
    setSelectedItem(value);
    setOpenForm(!openForm);
  };
  const handlePageChange = (page: number) => {
    setPage(page);
  };
  return (
    <div className="Offices">
      <PageHeader title={"Enquires"} backButton={true}>
        <div style={{ display: "flex" }}></div>
      </PageHeader>
      {loading ? (
        <LoadingBox />
      ) : (
        <DataTable
          data={data}
          handlePageChange={handlePageChange}
          meta={meta}
          page={page}
          pageSize={pageSize}
          onChange={(data: any) => editItem(data)}
        />
      )}
    </div>
  );
}

export default Enquiries;
