import { Col, Row } from "react-bootstrap";
import { FaCheckCircle } from "react-icons/fa";

function Section6() {
  return (
    <div>
      <div className="HomeScreen-section6-text1">
        Start today with right plans you choose
      </div>
      <Row>
        <Col sm={4} xs={12}>
          <div className="HomeScreen-section6-Box2">
            <div>
              <div className="HomeScreen-section6-text2">basic plan</div>
              <div className="HomeScreen-section6-text3">₹ 400</div>
              <div className="HomeScreen-section6-text4">
                per user per month
              </div>
            </div>
            <div>
              <div className="HomeScreen-section6-text5">FEATURES</div>
              <ul className="HomeScreen-section6-ul">
                <li>
                  <FaCheckCircle size={12} color="#fcde3f" />
                  &nbsp;features
                </li>
                <li>
                  <FaCheckCircle size={12} color="#fcde3f" />
                  &nbsp;features
                </li>
                <li>
                  <FaCheckCircle size={12} color="#fcde3f" />
                  &nbsp;features
                </li>
                <li>
                  <FaCheckCircle size={12} color="#fcde3f" />
                  &nbsp;features
                </li>
              </ul>
            </div>
          </div>
        </Col>
        <Col sm={4} xs={12}>
          <div className="HomeScreen-section6-Box2">
            <div>
              <div className="HomeScreen-section6-text2">1 year plan</div>
              <div className="HomeScreen-section6-text3">₹ 1200</div>
              <div className="HomeScreen-section6-text4">
                per user per month
              </div>
            </div>
            <div>
              <div className="HomeScreen-section6-text5">FEATURES</div>
              <ul className="HomeScreen-section6-ul">
                <li>
                  <FaCheckCircle size={12} color="#fcde3f" />
                  &nbsp;features
                </li>
                <li>
                  <FaCheckCircle size={12} color="#fcde3f" />
                  &nbsp;features
                </li>
                <li>
                  <FaCheckCircle size={12} color="#fcde3f" />
                  &nbsp;features
                </li>
                <li>
                  <FaCheckCircle size={12} color="#fcde3f" />
                  &nbsp;features
                </li>
              </ul>
            </div>
          </div>
        </Col>
        <Col sm={4} xs={12}>
          <div className="HomeScreen-section6-Box2">
            <div>
              <div className="HomeScreen-section6-text2">premium plan</div>
              <div className="HomeScreen-section6-text3">₹ 8000</div>
              <div className="HomeScreen-section6-text4">
                per user per month
              </div>
            </div>
            <div>
              <div className="HomeScreen-section6-text5">FEATURES</div>
              <ul className="HomeScreen-section6-ul">
                <li>
                  <FaCheckCircle size={12} color="#fcde3f" />
                  &nbsp;features
                </li>
                <li>
                  <FaCheckCircle size={12} color="#fcde3f" />
                  &nbsp;features
                </li>
                <li>
                  <FaCheckCircle size={12} color="#fcde3f" />
                  &nbsp;features
                </li>
                <li>
                  <FaCheckCircle size={12} color="#fcde3f" />
                  &nbsp;features
                </li>
              </ul>
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
}
export default Section6;
