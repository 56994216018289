import moment from "moment";
import { message } from "antd";
import API from "../../../config/api";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { GET } from "../../../utils/apiCalls";
import DataTable from "./components/DataTable";
import LoadingBox from "../../../components/loading";

function Subscription() {
  const { id } = useParams();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState<number>(1);
  const [meta, setMeta] = useState<any>();
  const [pageSize, setpageSize] = useState<any>(10);

  useEffect(() => {
    getData();
  }, [page, pageSize]);

  const handlePageChange = (page: number, take: number) => {
    setPage(page);
    setpageSize(take);
  };

  const getData = async () => {
    try {
      let url = API.LIST_SUBSCRIPTIONS + id;
      let response: any = await GET(url, null);
      console.log(response);
      if (response?.status) {
        setData(response?.data);
        setMeta(response?.meta);
      } else {
        message.error("oops.something gone wrong.");
      }
      setLoading(false);
    } catch (err) {
      setLoading(false);
    }
  };

  return (
    <div>
      {loading ? (
        <LoadingBox />
      ) : (
        <>
          <DataTable
            data={data}
            page={page || 1}
            current={page || 1}
            pageSize={pageSize}
            onChange={handlePageChange}
            total={meta?.itemCount || 0}
          />
          <br />
        </>
      )}
    </div>
  );
}

export default Subscription;
