const Cards = (props: any) => {
  return (
    <div className="Info-cards">
      <div className="Info-cardBox1">{props?.icon}</div>
      <div className="Info-cardBox2">
        <div className="Info-cardtxt1">{props?.label}</div>
        <div className="Info-cardtxt2">{props?.value}</div>
      </div>
    </div>
  );
};
export default Cards;
