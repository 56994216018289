import API from "../../../config/api";
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { GET, POST } from "../../../utils/apiCalls";
import LoadingBox from "../../../components/loading";
import { message} from "antd";
import DataTable from "./components/DataTable";

function Users() {
  const { id } = useParams();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState<number>(1);
  const [meta, setMeta] = useState<any>();
  const [isModal, setIsModal] = useState<any>({ isModal: false, user: {} });
  const [pageSize, setpageSize] = useState<any>(10);

  useEffect(() => {
    getData();
  }, [page, pageSize]);

  const getData = async () => {
    try {
      let url = `${
        API.USERS_LIST + id
      }?order=DESC&page=${page}&take=${pageSize}`;
      let response: any = await GET(url, null);
      if (response?.status) {
        setData(response?.data);
        setMeta(response?.meta);
      } else {
        message.error("oops.something gone wrong.");
      }
      setLoading(false);
    } catch (err) {
      setLoading(false);
    }
  };

  const handlePageChange = (page: number, size: number) => {
    setPage(page);
    setpageSize(size);
  };

  const DeleteUser = async (id: any) => {
    try {
      let deleteUrl = API.USERS_DELETE;
      let payload = {
        id: id?.id,
        ofcId: id?.office_id,
      };
      const userDelete: any = await POST(deleteUrl, payload);
      if (userDelete?.status) {
        message.success(userDelete?.message);
        getData();
      } else {
        message.error("something went wrong..!");
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsModal({ isModal: false, user: {} });
    }
  };

  return (
    <div>
      {loading ? (
        <LoadingBox />
      ) : (
        <>
          <DataTable
            data={data}
            current={page || 1}
            pageSize={pageSize || 10}
            onChange={handlePageChange}
            total={meta?.itemCount || 0}
            DeleteUser={(id: any) => DeleteUser(id)}
          />
          <br />
        </>
      )}
    </div>
  );
}

export default Users;