import "./styles.scss";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Col, Container, Row } from "react-bootstrap";
import { Button, Form, Input, message } from "antd";

import { login } from "../../redux/slices/userSlice";
import Header from "../../components/header";

function LoginScreen() {
  const dispatch = useDispatch();
  const navigation = useNavigate();

  const onFinish = async (val: any) => {
    try {
      if (val.username === "admin" && val.password === "12345") {
        let obj: any = {
          username: "admin",
          password: "1234",
        };
        message.success("login Success");
        dispatch(login(obj));
        navigation("/admin/home");
      } else {
        message.error("login faild");
      }
    } catch (err) {
      message.error("login faild");
    }
  };

  return (
    <div className="LoginScreen">
      <Header />
      <Container>
        <Row>
          <Col sm={4} xs={12}></Col>
          <Col sm={4} xs={12}>
            <div className="LoginScreen-Box">
              <div className="LoginScreen-txt1">Login Now</div>
              <Form onFinish={onFinish}>
                <Form.Item name={"username"}>
                  <Input placeholder="Enter username" size="large" />
                </Form.Item>
                <Form.Item name={"password"}>
                  <Input.Password placeholder="Enter password" size="large" />
                </Form.Item>
                <Form.Item>
                  <Button block type="primary" htmlType="submit" size="large">
                    Login
                  </Button>
                </Form.Item>
              </Form>
            </div>
          </Col>
          <Col sm={4} xs={12}></Col>
        </Row>
      </Container>
    </div>
  );
}

export default LoginScreen;
