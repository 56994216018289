import React, { useEffect, useState } from "react";
import { Button, Pagination, message } from "antd";
import { useParams } from "react-router-dom";
import { GET, POST } from "../../../utils/apiCalls";
import API from "../../../config/api";
import LoadingBox from "../../../components/loading";
import CreateModal from "./components/formModal";
import DataTable from "./components/DataTable";

function Trainers() {
  const { id } = useParams();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState<number>(1);
  const [meta, setMeta] = useState<any>();
  const [openForm, setOpenForm] = useState(false);
  const [editData, setEditData] = useState<any>();
  const [office, setOffice] = useState<any>([]);
  const pageSize = 10;

  useEffect(() => {
    getData();
    findOffice();
  }, [page]);

  const findOffice = async () => {
    try {
      let api = API.GET_BY_OFFICE + id;
      const response: any = await GET(api, null);
      if (response?.status) {
        setOffice(response?.data);
      } else {
        setOffice([]);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handlePageChange = (page: number) => {
    setPage(page);
  };

  const getData = async () => {
    try {
      let url = `${
        API.LIST_TRAINERS + id
      }?order=DESC&page=${page}&take=${pageSize}`;
      let body = {
        office_id: id,
      };
      let response: any = await GET(url, body);
      if (response?.status) {
        setData(response?.data);
        setMeta(response?.meta);
      } else {
        message.error("oops.something gone wrong.");
      }
      setLoading(false);
    } catch (err) {
      setLoading(false);
    }
    setLoading(false);
  };

  const onEdit = (data: any) => {
    try {
      setEditData(data);
      setOpenForm(!openForm);
    } catch (error) {
      console.log(error);
    }
  };

  const DeleteUser = async (id: any) => {
    try {
      let deleteUrl = API.USERS_DELETE;
      let payload = {
        id: id?.id,
        ofcId: id?.office_id,
      };
      console.log(id);
      console.log(deleteUrl);
      console.log(payload);
      const userDelete: any = await POST(deleteUrl, payload);
      if (userDelete?.status) {
        message.success('Trainer Deleted');
        getData();
      } else {
        message.error("something went wrong..!");
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div>
      {loading ? (
        <LoadingBox />
      ) : (
        <>
          <div className="tabs-header">
            <div>Total Trainers ( {meta?.itemCount} )</div>
            <div>
              <Button type="primary" onClick={() => onEdit({})}>
                Create Trainer +
              </Button>
            </div>
          </div>
          <DataTable
            data={data}
            onChange={(data: any) => onEdit(data)}
            DeleteUser={(id: any) => DeleteUser(id)}
          />
          <Pagination
            current={page || 1}
            pageSize={pageSize || 10}
            total={meta?.itemCount || 0}
            defaultCurrent={1}
            responsive={true}
            defaultPageSize={pageSize || 10}
            disabled={false}
            hideOnSinglePage={true}
            onChange={handlePageChange}
            style={{ marginTop: "20px", textAlign: "center" }}
          />
          <br />
          {openForm ? (
            <CreateModal
              open={openForm}
              editData={editData}
              onSuccess={() => {
                getData();
                onEdit({});
              }}
              onBlockUnblock={() => getData()}
              onCancel={() => onEdit({})}
              office={office}
            />
          ) : null}
        </>
      )}
    </div>
  );
}

export default Trainers;
